@import './../../stylesheets/global.scss';

.Groups-container {
  height: 100%;
  width: 100%;
  
  top: 0;

  .create-group-modal {
    height: auto;
    width: 50%;
    margin: auto;
    margin-top: 15%;
    
    text-align: center;

    ul {
      text-align: center;
    }
  
    .create-group-button-container {
      display: flex;
      justify-content: space-around;
      margin: auto;
    }
  }

  .groups {
    display: block;
    margin: 0 auto;
    padding-top: 5%;
    max-width: 85%;

    #customized-menu {
      width: 100%;
    }

    .dropdown-button-container {
      display: flex;
      justify-content: space-around;
      height: 100%;
      width: 50%;
      margin: 0;
      margin-bottom: 2.5%;

      left: 0;      

      .groups-dropdown-button {
        display: flex;
        justify-content: flex-start;
        position: relative;
        height: 100%;

        background-color: white;
        border-radius: 5%/10%;
        color: grey;
        text-align: center;

      }  

      .search-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 20%;
        margin-left: 5%;

        background-color: white;
        border-radius: 2%/10%;
        label {
          color: darkblue;
        }
      }

      .add-group {
        width: 20px;
      }
    }


    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-top: 10%;
    }

    .grided-groups {
      display: flex;
      justify-content: center;
      position: relative;
      height: auto;
      width: auto;
      margin-left: auto;
      margin-right: auto;

      .fading-text {
        animation-name: text-back-to-grey;
        animation-duration: 0.75s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }
  }

  .row-fade:hover {
    animation-name: Background-white-to-grey;
    animation-duration: 0.75s;
    animation-direction: alternate;
    background-color: grey;
  }
}
