@import './../../stylesheets/global.scss';

.Top-nav-bar {
  position: fixed;
  width: 100%;
  height: 5%;
  margin: 0;

  background: linear-gradient(332deg, rgba(208,208,208,1) 0%, rgba(135,135,135,1) 100%);
  z-index: 5;
  
  .head-title {
    position: fixed;
    width: 100%;

    color: white;
    font-size: 32px;
    text-align: center;
    z-index: 0;
  }

  @media screen and (max-width: 632px) {
    .head-title {
      font-size: 24px;
    }
  }

  // Material-ui changes
  .MuiButtonBase-label {
    display: flex;
    justify-content: center;
    width: auto;
    padding: 0;
    margin: 0;
  }
  .MuiListItemIcon-root {
    display: flex;
    justify-content: center;
    width: auto;
    margin: 0;
    padding: 0;
  }
  
  .mobile-buttons {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    width: auto;
    height: 100%;
    margin: 0;
    padding: 0;

    top: 0;

    .sound-toggle {
      position: relative;
      height: auto;
      width: auto;
    }  
    .mobile-direct {
      display: inline;
      position: relative;
      button:nth-child(1) {
        margin-left: 2%;
      }
      button:nth-child(2) {
        margin-right: 2%;
      }
    }
    button {
      width: auto;
    }
  }
  .myAccount-options {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 100%;
    width: auto;
    margin: 0;

    right: 0;
    top: 0;

    .username {
      height: auto;
      width: 20%;
      padding: 0;
      margin-right: .75%;

      cursor: pointer;
      font-size: small;

    }
    .upload {
      height: auto;
      width: 20%;
      margin-right: .75%;
      padding: 0;

      cursor: pointer;
      font-size: small;
    }
  }

  button {
    margin: 0;
    margin-right: .75%;
    height: 100%;

    font-family: Quicksand;
    font-size: medium;
    outline: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sound-toggle {
    position: absolute;
    max-height:100%;
    max-width:100%;

    top: 0;
    z-index: 2;
  }

  .main-menu {
    width: auto;
    margin: 0;
    float: right;
    z-index: 1;
  }
}


