@import './../../stylesheets/global.scss';

.Upload-web-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  font-family: Quicksand;

  .upload-modal {
    height: auto;
    width: 45%;
  
    h2 {
      margin: 0 auto;
      text-align: center;
    }
    .loader svg {
      height: auto;
      margin: 0 auto;
    }
    p {
      margin: 0 auto;
      text-align: center;
    }
  }

  .meme-forum {
    height: auto;
    width: auto;
    
    animation: fade-up .5s .5s ease both;
    border-radius: 6.25%/5%;
    box-shadow: 10px 5px 5px black;
    font-family: Quicksand;
    opacity: 0;
    overflow: hidden;
    transform: translateY(20px);
    
    .upload-head {
      width: 100%;
      height: 100%;

      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      text-align: center;
      p {
        height: 20%;
        
        background: rgb(255, 183, 49);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        color: black;
        font-size: 40px;
        margin: 0;
        padding: 50px 0;
        .load {
          display: inline-block;

          animation: fade-right .5s .65s ease both;
          color: #3f3a3a;
          text-decoration: underline;
          font-weight: 100;
          margin-left: -8px;
          opacity: 0;
          transform: translateX(-20px);
        }
      }
    }

    .upload-files {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      height: 100%;
      padding-top: 2.5%;
      padding-bottom: 2.5%;

      background-color: white;

      .file-prompt {
        width: 100%;
        margin: auto;

        text-align: center;
        &.hidden {
          display: none;
        }
        input {
          visibility: hidden;
        }
        i {
          color: lightgray;
          font-size: 65px;
        }
        p {
          font-size: 14px;
          line-height: 1.4;
          b,
          a {
            color: orangered;
          }
        }
        &.active {
          border: dashed 2px $blue;
          i {
            animation: file .5s ease both;
            box-shadow: 0 0 0 -3px #fff,
            0 0 0 lightgray,
            0 0 0 -3px #fff,
            0 0 0 lightgray;
          }
          .pointer-none{
            pointer-events: none;
          }
        }
      }

      .link-field {
        width: 80%;
        margin: auto;
        text-align: center;

        .inital-preview-display {
          .preview-input {
            position: absolute;
          }
        }

        input {
          visibility: hidden;
        }
        i {
          font-size: 65px;
          color: lightgray;
        }
        p {
          font-size: 14px;
          line-height: 1.4;
          b,
          a {
            color: orangered;
          }
        }
        &.active {
          border: dashed 2px $blue;
          i {
            animation: file .5s ease both;
            box-shadow: 0 0 0 -3px #fff,
            0 0 0 lightgray,
            0 0 0 -3px #fff,
            0 0 0 lightgray;
          }
          .pointer-none{
            pointer-events: none;
          }
        }
        .mediaUrl {
          width: 100%;

          border-radius: 2%/30%;
          border-color: black;
          outline: none;
          font-family: Quicksand;
          font-size: small;
        }
      }

      .upload-error {
        width: 100%;
        text-align: center;
      }
      footer {
        height: 0;
        width: 100%;
        margin: 0 auto;

        .divider {
          width: 0;
          margin: 0 auto;

          border-top: solid 4px #3D9CC0;
          overflow: hidden;
          text-align: center;
          transition: width .5s ease;
          span {
            display: inline-block;
            font-size: 12px;
            padding-top: 8px;

            transform: translateY(-25px);
          }
        }
        &.hasFiles {
          height: auto;
          .divider {
            width: 100%;
            span {
              transform: translateY(0);
              transition: transform .5s .5s ease;
            }
          }
          .list-files {
            width: 100%;
            max-height: 210px;
            margin: 0 auto;
            margin-top: 15px;
            padding-left: 5px;

            text-align: center;
            overflow-x: hidden;
            overflow-y: auto;
            .files {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              width: auto;
              min-height: 50px;

              opacity: 0;

              .name {
                width: auto;

                font-size: 12px;
                overflow: hidden;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .progress {
                height: 5px;
                width: 175px;
                background: linear-gradient(to left, rgba( 0,172,193, .2), rgba(84,58,183, .8)) no-repeat;          background-size: 100% 100%;
                border: solid 1px lightgray;
                &.active {
                  animation: progress 30s linear;
                }
              }
      
              .done {
                position: relative;
                height: 40px;
                width: 40px;
                margin-left: -10px;
                
                cursor: pointer;
                background: $blue-gradient;
                border-radius: 50%;
                transform: scale(0);
                &:before {
                  position: absolute;
                  content: "View";

                  font-size: 24px;
                  left: -5px;
                  top: 0;
                  opacity: 0;
                }
                &:hover:before {
                  transition: all .25s ease;
                  top: -30px;
                  opacity: 1;
                }
                #path {
                  fill: transparent;
                  stroke: #fff;
                  stroke-dasharray: 7387.59423828125 7387.59423828125;
                  stroke-dashoffset: 7387.59423828125;
                  stroke-width: 50px;
                }
              }          
            }
          }
        }
        .importar {
          position: absolute;
          font-family: Quicksand;
          margin: auto;

          background: transparent;
          border-radius: 4px;
          bottom: 20px;
          border: solid 1px $blue-gradient;
          color: $blue;
          cursor: pointer;
          font-size: 12px;
          left: 0;
          line-height: 1;
          opacity: 0;
          outline: none;
          padding: 8px 15px;
          right: 0;
          transform: translateY(15px);
          visibility: hidden;
          &.active {
            transform: translateY(0);
            transition: transform .5s 1.5s ease, opacity .5s 1.5s ease;
            opacity: 1;
            visibility: visible;
          }
          &:hover{
            background: $blue-gradient;
            color: #fff;
          }
        }
      }
    }

    .type-button-container {
      display: flex;
      justify-content: space-around;
      height: auto;
      width: 100%;
      margin: 0 auto;
    }
  }

  .meme-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    height: auto;
    width: 100%;
    margin: auto 0;
    min-width: 100%;

    background: rgb(255, 183, 49);
    opacity: 1;
    .groups {
      width: 85%;
      margin: 8px 0;
      max-height: 20%;
      background-color: white;
    }
    .desc-container {
      display: flex;
      justify-content: space-around;
      max-height: 20%;
      width: 100%;
      margin: 8px 0;
      .desc {
        height: 50%;
        width: 85%;
        max-width: 85%;

        border-color: transparent;
        border-radius: 1rem;
        font-family: Quicksand;
        font-size: 16px;
        outline: none;
        text-indent: 5%;
      }
    }
  }
  
  .buttons-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0 auto;

    background: rgb(255, 183, 49);
    padding-bottom: 5%;
    .test-div {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-bottom: 5%;
      .test-meme {
        width: 35%;

        background-color: steelblue;
      }
    }

    .upload-buttons-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      .cancel {
        height: 100%;
        width: 35%;
        margin: 0;

        background-color: grey;
        cursor: pointer;
        font-family: Quicksand;
        right: 0;
      }
      .sendit {
        height: 100%;
        width: 35%;
        margin: 0;

        cursor: pointer;
        background-color: rgba(0,172,193,1);
        font-family: Quicksand;
        right: 0;
      }
    }
  }
}

@media(max-width: 632px) {
  .Upload-web-page {
    .files {
      .progress {
        width: 100px;
      }
    }
    .cancel, .sendit {
      border-radius: 10/10%;
    }
    .meme-forum {
      height: auto;
      width: 85%;
    }
  }
}

@keyframes file {
  50% {
    box-shadow: -8px 8px 0 -3px #fff,
    -8px 8px 0 lightgray,
    -8px 8px 0 -3px #fff,
    -8px 8px 0 lightgray;
  }
  100% {
    box-shadow: -8px 8px 0 -3px #fff,
    -8px 8px 0 lightgray,
    -16px 16px 0 -3px #fff,
    -16px 16px 0 lightgray;
  }
}

@keyframes progress {
  from {
    background-size: 0 100%;
  }
  to {
    background-size: 100% 100%;
  }
}

@keyframes fade-up {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-right {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade {
  to {
    opacity: 1;
  }
}
