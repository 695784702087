@import './../../stylesheets/global.scss';

.Mobile-viewer {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;


  .meme-renderer {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 95%;
    width: 100%;
    max-width: 100%;
    margin: 0;

    .memeDiv {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      display:flex;
      justify-content: center;
      align-items: center;
      overflow: scroll;
      background-color: black;
    }
  }
}
