@import './../../stylesheets/global.scss';

.Viewer-Container {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0 auto;

  .content {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 95%;
    margin: 0;

    bottom: 0;

    // only for desktop
    .content-description-pannel {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;

      background-color: transparent;
      opacity: 1;

      .description {
        height: 30%;
        width: 100%;

        color: white;
        text-align: center;
        font-family: Quicksand;
      }
      .slider {
        margin: 5px;
      }
    }

    // video/photo/audio inside
    .content-observation-pannel {
      display:flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;

      background-color: transparent;
      margin: 0;
      padding: 0;
    }

    // only for desktop
    .content-comment-pannel {
      display: block;
      height: 100%;
      width: 100%;

      background-color: transparent;
      margin: 0;
      padding: 0;

      .the-line {
        height: 1px;
        width: 100%;

        background-color: black;
        margin: 0;
        padding: 0;
      }

    }

  }
}

@media screen and (max-width: 632px){
  .Viewer-Container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    .content {
      display: block;
      height: 100%;
      position: absolute;
    }
  }


  .content-description-pannel {
    display: none;
    .description {
      background: radial-gradient(rgba(0,0,0, .77), transparent);
    }
  }

  .content-observation-pannel {
    width: 100%;
    height: auto;
  }

  .content-comment-pannel {
    display: none;
  }
}
