$instagram: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
$Twitter: #1DA1F2;
$reddit: url('../../photos/reddit.svg');

$shadow: 0px 3px 10px rgba(0,0,0,.25);

$test: pink;
$green-blue: #4db6ac;
$blue-gradient: linear-gradient(33deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
$blue: rgba(84,58,183,1);

// TODO: make a display flex div template

// base element re-assignments
label {
  font-family: QuickSand;
}
// background color
.black {
  background-color: #000;
}

// -- text --
.default-font {
  font-family: 'Quicksand';
}
.white-text {
  color: white;
}
.grey-text, .gray-text {
  color: grey;
}
.error-text {
  color: orangered;
}

.center-text {
  text-align: center !important;
}

.bold {
  font-weight: 900 !important;
}

// spacing
.space-taker-upper {
  width: 100%;
  height: auto;
  margin: 0;
}


// -- animations --

// todo: seperate file to import

@keyframes Background-white-to-grey {
  from {
    background-color: white;
  }
  to {
    background-color: grey;
  }
}

@keyframes text-back-to-grey {
  from {
    color: black;
  }
  to {
    color: grey;
  }
}


// back button - sign in page
@keyframes fade-out-to-left-from-center {
  0% {
    opacity: 1;
    transform: translate(0,0);
  }
  100% {
    opacity: 0;
    transform: translate(-35%, 0);
  }
}


// sign in request
@keyframes fade-out-to-right-from-center {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    transform: translate(35%, 0);
  }
}

// on failed login - sign-in/sign-up
@keyframes fade-into-center-from-right {
  0% {
    opacity: 0;
    transform: translate(35%, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

// on render - sign-in/sign-up
@keyframes fade-into-center-from-left {
  0% {
    opacity: 0;
    transform: translate(-35%, 0);
  }
  100% {
    opacity: 1; 
    transform: translate(0, 0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes initalize-black-background-expand {
  0% {
    background-color: black;
    height: 0%;
    width: 1%;
    opacity: 1;
    top: 0;
  }

  30% {
    height: 50%;
    width: 1%;
    opacity: 1;
  }

  100% {
    width: 100%;
    opacity: 1;
    background-color: black;
  }
}

@keyframes initVideoB {
  0% {
    background-color: black;
    height: 0%;
    width: 1%;
    opacity: 1;
    bottom: 0;
  }

  30% {
    height: 50%;
    width: 1%;
    opacity: 1;
  }

  100% {
    width: 100%;
    opacity: 1;
    background-color: black;
  }
}