@import '../../stylesheets/global.scss';

.big-label {
  width: 100%;
  margin: auto;

  color: rgb(63, 58, 58);
  font-family: QuickSand;
  font-size: xx-large;
  text-align: center;
}

.margin-auto {
  position: absolute;
  margin: auto;

  opacity: 0;
  z-index: 2;
}

/* Style the container */
.sign-up-page {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  .sign-in {
    color: $blue;
    font-family: 'Quicksand';
    font-weight: 500;
    text-decoration: underline;
  }
  .sign-up-form-parent {
    height: auto;
    width: auto;
    border-radius: 5%;
    background-color: #f2f2f2;

    max-width: 40%;
    padding: 20px;
    .row:nth-child(n + 1) {
      display: flex;
      flex-direction: column;
    }

    .form-confirmPassword {
      display: block;
      position: relative;
    }
  }

  li {
    font-family: Quicksand;
  }

  /* Style the label to display next to the inputs */
  label {
    display: inline-block;
    padding: 12px 0 12px 0;
  }

  /* Style the submit button */
  input[type=submit] {
    padding: 12px 20px;

    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    float: right;
  }

  /* Floating column for labels: 25% width */
  .col-25 {
    width: 25%;
    margin-top: 6px;

    float: left;
  }

  /* Floating column for inputs: 75% width */
  .col-75 {
    width: 75%;
    margin-top: 6px;
  }

  .form-inputs {
    height: 100%;
    width: 75%;
  }

  .account-requirements {
    height: auto;
    width: 100%;
  }
}

// animate in from left
.on-render {
  animation-name: fade-into-center-from-left;
  animation-duration: 1.15s;
  animation-iteration-count: 1;

  opacity: 1;
}
// animate out to left
.on-back-button {
  animation-name: fade-out-to-left-from-center;
  animation-duration: .75s;
  animation-iteration-count: 1;

  opacity: 0;
}
// animate out to right
.on-submit-button {
  animation-name: fade-out-to-right-from-center;
  animation-duration: .5s;
  animation-iteration-count: 1;

  opacity: 0;
}
// animate in from right
.on-failed-login {
  animation-name: fade-into-center-from-right;
  animation-duration: 1s;
  animation-iteration-count: 1;

  opacity: 1;
}

@media screen and (max-width: 632px) {
  .sign-up-page {
    .sign-up-form-parent {
      min-width: 80%;
      .row {
        label {
          padding-bottom: 0;
          padding-right: 0;
        }
        .MuiFormControl-root.MuiTextField-root.form-inputs.col-75 {
          width: 95%;
          padding: auto;
        }
      }
    }
  }
}
