@import './../../stylesheets/global.scss';

.Group-page-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: scroll;

  .group-container {
    width: 80%;
    height: 100%;
    margin: auto;
    .white-space-height {
      height: 5%;
    }
    .group-name {
      background-color: rgba(0, 0, 0, .66);
      color: white;
      text-align: center;
      font-family: QuickSand;
      font-size: xx-large;
    }

    

    .meme-container {
      color: ivory;
      background: grey;
      padding: 0;
      position: relative;
      display:block;

      .link-wp {
        width: 100%;
        height: 100%;
        
        .description-container {
          color: white;
          position: absolute;
          font-family: QuickSand;
          font-size: xx-large;
          opacity: 0;
          height: 100%;
          margin: 5% 0 10% 0;
        }

      }

      .preview-image {
        height: auto;
        width: 100%;
        margin: 5% 0 2.5% 0;
      }

      .hovering {
        width: 100%;
        color: white;
        position: absolute;

        animation-name: fade-in;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;  
      }

      .unhovered {
        width: 100%;
        color: white;
        position: absolute;

        animation-name: fade-out;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;  
      }
      
      .description-header {
        position: relative;
        height: 20%;
        font-size: 36px;
        font-family: Quicksand;
        border-style: solid;
        border-color: black;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px;
      }

      .meme-div {
        position: relative;
        height: 100%;
      }

      .described {
        height: 80% !important;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    background: transparent;
  }

  to {
    opacity: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .75) 0%, transparent 60%);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .75) 0%, transparent 60%);
  }

  to {
    opacity: 0;
    background: transparent;
  }
}