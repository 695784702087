@import './../../../stylesheets/global.scss';

.image-container {
  display: block;
  position: absolute;
  height: fit-content;
  width: 100%;
  margin: 0px;
  padding: 0px;
  
  img {
    position: relative;
    height: auto;
    width: 100%;
    margin: 0;

    outline: none;
    padding: 0;
  }
}