@import './stylesheets/global.scss';

.App {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  background: linear-gradient(33deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  margin: auto;
  overflow: auto;
}

// base element re-assignments
html, #root, body {
  position: absolute;
  
  margin: 0px;
  min-height: 100%;
  min-width: 100%;
  padding: 0px;
}
textarea {
  resize: none;
}
