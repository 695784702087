@import './../../stylesheets/global.scss';

.Bottom-Nav {
  display: flex;
  align-items: flex-end;
  max-height: 10%;
  width: 100%;
  margin: 0 auto;

  .direct {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    align-content: space-around;
    flex-direction: row-reverse;
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;

    opacity: 1;

  }

  .mobile-nav {
    display: flex;
    justify-content: space-around;    
    height: 100%;
    width: 100%;

    svg {
      fill: white;
    }
  }


  @media screen and (max-width: 632px) {
    .direct {
      position: absolute;
      justify-content: space-between;
      flex-direction: row-reverse;
      height: 10%;
      width: 60%;

      bottom: 10%;
      opacity: .25;
      z-index: 5;
      
      element {
        width: 25%;
      }
    }

    .sound-toggle {
      width: 65%;
    }

    .mobile-click {
      animation-name: opacity-clear;
      animation-iteration-count: 1;
      animation-duration: 3s;
      animation-delay: 3.5s;

      opacity: 1;
    }
  }

  .sign-in-buttons, .sign-up-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 30%;
    width: 100%;
    margin: 0 auto;

    button {
      height: auto;
      width: auto;
      font-family: Quicksand;
      font-size: 18px;
    }
  }

  input {
    width: 90%;
    margin: 0 auto;
  }

  input[type=submit].disabled {
    width: 35%;
  }
  input[type=submit].abled {
    width: 35%;
  }
}


@keyframes opacity-clear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}

@media screen and (max-width: 632px) {
  .mobile-nav {
    button {
      width: 100%;
      margin: 0;

      background: rgba(0, 0, 0, .5);
      border-radius: 0;
      border-width: 2px;
      border-color: black;
      border-style: solid;
    }
    button:nth-child(2) {
      width: 30%;
    }
    background: $blue-gradient;
    z-index: 1;
  }

  .direct {
    button:nth(2) {
      height: 24px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}