@import '../../stylesheets/global.scss';

/* Style the container */
.sign-in-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .sign-in-form-parent {
    height: auto;
    width: 30%;
    max-width: 40%;
    padding: 20px;

    border-radius: 5%;
    background-color: #f2f2f2;
  }

  /* Style inputs, select elements and textareas */
  input[type=text], select, textarea{
    width: 100%;
    padding: 12px;

    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
  }

  li {
    font-family: Quicksand;
  }

  /* Style the label to display next to the inputs */
  label {
    display: inline-block;
    padding: 12px 12px 12px 12px;
  }

  /* Style the submit button */
  input[type=submit] {
    padding: 12px 20px;

    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
  }

  /* Floating column for labels: 25% width */
  .col-25 {
    width: 25%;
    float: left;
    margin-top: 6px;
  }

  /* Floating column for inputs: 75% width */
  .col-75 {
    width: 75%;
    margin-top: 6px;
  }

  /* Clear floats after the columns */
  .row:after {
    display: table;
    clear: both;
    content: "";
  }

  .form-inputs {
    height: 100%;
    width: 75% !important;
  }

  .account-requirements {
    height: auto;
    width: 100%;
  }
}

@media(max-width: 632px) {
  .sign-in-page {
    .sign-in-form-parent {
      min-width: 80%;
      .row {
        label {
          padding-bottom: 0;
        }
        .MuiFormControl-root.MuiTextField-root.form-inputs.col-75 {
          width: 95% !important;
          padding: auto !important;
        }
      }
    }
  }
}

// animate in from left to center
.on-render {
  animation-name: fade-into-center-from-left;
  animation-duration: 1.15s;
  animation-iteration-count: 1;

  opacity: 1;
}
// animate out from center to left
.on-back-button {
  animation-name: fade-out-to-left-from-center;
  animation-duration: .35s;
  animation-iteration-count: 1;

  opacity: 0;
}


// LOGIN ANIMATIONS

// animate out from center to right
.on-submit-button {
  animation-name: fade-out-to-right-from-center;
  animation-duration: .5s;
  animation-iteration-count: 1;
  opacity: 0;
}

// animate in from right to center
.on-failed-login {
  animation-name: fade-into-center-from-right;
  animation-duration: 1s;
  animation-iteration-count: 1;
  opacity: 1;
}

// for LoadingSVG
.margin-auto {
  position: absolute;
  margin: auto;

  opacity: 0;
  z-index: 2;
}

.sign-up {
  text-align: center;
  .join-here {
    color: $blue;
    font-family: 'Quicksand';
    font-weight: 900;
    margin-bottom: 5%;
    text-decoration: underline;
  }
  margin: 0 auto 5% auto;
  width: 100%;
}

.big-label {
  width: 100%;
  color: rgb(63, 58, 58);
  margin: auto;

  font-family: QuickSand;
  font-size: xx-large;
  text-align: center;
  text-decoration: underline;
}

.fade-in {
  animation-name: fade-in;
  animation-duration: .25s;
  animation-iteration-count: 1;

  opacity: 1;
}

.fade-out {
  animation-name: fade-out;
  animation-duration: .25s;
  animation-iteration-count: 1;

  opacity: 0;
}
