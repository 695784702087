@import './../../../stylesheets/global.scss';

.VideoViewer {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  background-color: black;
  bottom: 0;
  flex-wrap: wrap;
  position: absolute;

  .bar-container {
    position: relative;
    height: 2%;
    width: 100%;
    .playback-background-bar {
      position: absolute;
      height: 100%;
      width: 100%;

      background: linear-gradient(94deg, rgba(255,139,0,1) 75%, rgba(255,0,119,1) 100%);
    }
    .playback-black-bar {
      position: absolute;
      height: 100%;
      width: 100%;

      background: black;
    }
  }
  video {
    position: relative;
    height: 98%;
    width: 96%;

    margin: 0;
    outline: none;
  }

  .fade-in {
    animation: fade-in ease 2s;
  }
}

